import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const WorkList = ({ projectSlug, projectTitle, previewImage, colour }) => {

    return (
        <div >

            <h3 class="projectListTitle" >
                <Link to={`/work/${(projectSlug)}`}>
                    {projectTitle} </Link>
            </h3>

        </div>

    )



}