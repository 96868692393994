import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';




export const ProjectProcess = ({ data }) => {


    return (

        <div class="ProjectProcess" 
        style={{
            backgroundColor: data.color
        }}>

            <h3> Project Proccess </h3>

            { data.processList && documentToReactComponents(data.processList.json)}

            


        </div>

    );


}



