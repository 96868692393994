import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { motion, AnimatePresence, useViewportScroll } from 'framer-motion';
//import ReactDOM from 'react-dom';
import ProgressiveImage from "react-progressive-image";
import { Footer } from '../components/Footer';
import { useMediaQuery } from 'react-responsive';
import { Featured } from '../components/Featured';

import floatingSmile from '../assets/floatingSmile.png';
import floatingSmilePlaceholder from '../assets/floatingSmile-placeholder.png';

import jeux from '../assets/friends-stickers-jeux.png';
import chrome from '../assets/Project_Thumbnails_thisisoke-02-p-1080.png';
import tiiwa from '../assets/Tiiwa-discover-thumbnail-01-p-1080.png';
import suum from '../assets/suum-mockup.png';
import { CircleRotate } from '../components/CircleRotate';



//Query to get project information for the Featured projects
const query = `
{
    projectCollection(where: {featured: true}) {
      items {
        projectSlug
        projectTitle
        projectOverview
        colour
        projectTagCollection {
          items {
            tag
            tagName
          }
        }
        mainImage {
          title
          description
          size
          width
          height
          url
        }
      }
    }
  } `





export const Home = () => {

    const [isShown, setIsShown] = useState(false);
    const [featuredProj, setFeaturedProj] = useState({});
    const [projectData, setProjectData] = useState(false);
    const [roll, setRoll] = useState(true);
    const [roll1, setRoll1] = useState(false);
    const [roll2, setRoll2] = useState(false);
    const [roll3, setRoll3] = useState(false);

    const { scrollYProgress } = useViewportScroll();


    const [cursorXY, setCursorXY] = useState({ x: 0, y: 0 });

    // const isMobile = useMediaQuery({ query: '(max-width: 425px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const isTabletOrDesktop = useMediaQuery({ query: '(max-width: 1024px)' })
    const isLargeDesktop = useMediaQuery({ query: '(max-width: 1224px)' })

    function map_range(value, low1, high1, low2, high2) {
        return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
    }

    useEffect(() => {

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

        const fetchProjectInfo = async () => {

            const data = await axios(
                {
                    method: 'post',
                    url: "https://graphql.contentful.com/content/v1/spaces/3mlo7pqpvlcv/",

                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer uff5octNHq6dSeF3yn9g294jTO0-oe37un12WtJpK-s"
                    },
                    data: JSON.stringify({ query }),

                })

                .then((response) => {
                    const projectReturn = response.data.data.projectCollection.items;
                    setFeaturedProj(projectReturn);
                    setProjectData(true);

                    console.log(projectReturn);

                }).catch(err => {
                    setProjectData(false);
                });


            // const items = await fetchItem.json();
            // console.log(items);

            // setProject(items[id]);

            // (project != null  ? console.log("happy") : console.log("sad"));
        }

        fetchProjectInfo();


        const moveCursor = (e) => {
            const x = e.clientX - 80;
            const y = e.clientY - 100;
            const xT1 = e.clientX - 250;
            const yT1 = e.clientY - 280;

            const xT2 = e.clientX - 525;
            const yT2 = e.clientY - 250;

            const xT3 = e.clientX - 525;
            const yT3 = e.clientY - 200;

            //console.log(e);
            //console.log(window.innerWidth,window.innerHeight);
            // console.log(map_range( xT, 0, 400, 0, window.innerHeight), "Map Range"); 
            // console.log("xT:", xT1, " yT:", yT1);
            //console.log("x:",x, " y:", y);
            setCursorXY({ x, y, xT1, yT1, xT2, yT2, xT3, yT3 })
        }

        window.addEventListener('mousemove', moveCursor)
        return () => {
            window.removeEventListener('mousemove', moveCursor)
        }

        
    }, [])

    useEffect(() => {

        var body = document.body,
            html = document.documentElement;

        var height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);

        function handleScroll() {
            //window.scrollY

            //console.log("scrolled");
            //console.log(window.innerHeight);

            //console.log("Height: ", height, "window.scrollY: ", window.scrollY, "Division: ", (window.scrollY / height));

            if ((window.scrollY / height) < 0.006) {

                //Show Nothing
                //Hide other Elements
                setRoll(true);
                setRoll1(false);
                setRoll2(false);
                setRoll3(false);

            }

            //0.6% Scrolled
            if ((window.scrollY / height) > 0.006) {

                //console.log("You have scrolled the page at least a bit, 1st element appears");

                //Show
                setRoll(true);
                //Hide other Elements
                setRoll1(false);
                setRoll2(false);
                setRoll3(false);
            }


            //20% Scroll
            if ((window.scrollY / height) > 0.20) {
                //console.log("I am 20% on the page");
                //Show
                setRoll(true);
                setRoll1(true);
                //Hide other Elements
                setRoll2(false);
                setRoll3(false);
            }

            //30% Scroll
            if ((window.scrollY / height) > 0.40) {
                //console.log("I am 40% on the page");
                //Show
                setRoll(true);
                setRoll1(true);
                setRoll2(true);
                //Hide other Elements
                setRoll3(false);
            }

            //45% Scroll
            if ((window.scrollY / height) > 0.60) {
                //console.log("I am 50% on the page");
                //Show
                setRoll(true);
                setRoll1(true);
                setRoll2(true);
                setRoll3(true);
                //Hide other Elements

            }

        }

        window.addEventListener('scroll', handleScroll, false);


        return () => {
            window.removeEventListener('scroll', handleScroll, false);
        }
        

    }, [window.scrollY])

    const transtionPic = { duration: 0.2, ease: [0.43, 0.13, 0.23, 0.96] };

    return (

        //add imported classes here
        //always have div to place component notes
        <div>
            {/* {isShown && ( 
            <div
                className="cursor"
                style={{
                    transform: `translate3d(${cursorXY.x}px, ${cursorXY.y}px, 0)`,
                }}
            />)} */}

            <section id="hero">
                <div>
                    <motion.h1
                        animate={{}}
                        className="logo-text"
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}

                    >
                        thisisoke
                </motion.h1>
                    <div className="navBar">
                        <Link to='/about'>ABOUT</Link>
                        <Link to='/work'>WORK</Link>
                    </div>
                </div>

                <p className="large-text">The portfolio of a <span className="roundedBoxText black" style={{ backgroundColor: "#59D798", borderColor: "#59D798" }}> designer</span> , <span className="roundedBoxText black "  style={{ backgroundColor: "#7DB8F8", borderColor: "#7DB8F8"}}>front-end developer</span> &amp; <span className="roundedBoxText black "  style={{ backgroundColor: "#f87d7d", borderColor: "#f87d7d"}}>ar/vr prototyper</span> who creates engaging experiences through storytelling.</p>


                <AnimatePresence>
                    {/* Use Progressive component to load loquality version of image first before big qualty version */}
                    {isShown && (
                        <ProgressiveImage
                            // delay={2000} //Delay to 2 seconds to test
                            src={floatingSmile}
                            placeholder={floatingSmilePlaceholder}>
                            {(src, loading) => (
                                <motion.img
                                    key="floatingImage"
                                    style={{
                                        opacity: loading ? 0.5 : 1
                                    }}

                                    src={src}
                                    alt='floating Photo of Seyitan Oke'
                                    className="floatingImage"
                                    initial={{
                                        scale: 0,
                                        opacity: 0,
                                        x: cursorXY.x,
                                        y: cursorXY.y
                                    }}
                                    animate={{
                                        scale: 1,
                                        opacity: 1,
                                        x: cursorXY.x,
                                        y: cursorXY.y,

                                        transition: { ...transtionPic }
                                    }}
                                    // transition={{
                                    //     type: "spring",
                                    //     stiffness: 260,
                                    //     damping: 20
                                    // }}
                                    exit={{ scale: 0, opacity: 0 }}
                                />
                            )}
                        </ProgressiveImage>
                    )}
                </AnimatePresence>

                <div className="scrollRevealCircle">

                    {/* <CircleRotate  text={"Featured Work"}/> */}

                    <div>
                    
                    <CircleRotate text={"Featured Work"}/>
                    
                    <p>↓</p>
                    </div>
                    {/* <a href="#featuredSection"></a> */}

                </div>
            </section>




            {projectData ?
                <section id="featuredSection" >



                    {/* Render featrued project one in first left position */}
                    {featuredProj[0] &&
                        <Featured imgSrc={featuredProj[0].mainImage.url} preloadImgSrc={featuredProj[0].mainImage.url} rotate={44} w={"62%"} h={"500px"} backgroundColor={featuredProj[0].colour} posTranslateX={"1%"} posTranslateY={"0%"} rotatePos1={-1} rotatePos2={2} link={featuredProj[0].projectSlug} linkText={featuredProj[0].projectTitle} tags={featuredProj[0].projectTagCollection.items} animateFlag={roll} />

                    }

                    {/* Render featured project two in right middle position */}
                    {featuredProj[1] &&
                        <Featured imgSrc={featuredProj[1].mainImage.url} preloadImgSrc={featuredProj[1].mainImage.url} rotate={44} w={"70%"} h={"500px"} backgroundColor={featuredProj[1].colour} posTranslateX={"50%"} posTranslateY={"0%"} rotatePos1={2} rotatePos2={-1} link={featuredProj[1].projectSlug} linkText={featuredProj[1].projectTitle} tags={featuredProj[1].projectTagCollection.items} animateFlag={roll1} />
                    }

                    {/* Render featured project three in left bottom position */}
                    {featuredProj[2] &&
                        <Featured imgSrc={featuredProj[2].mainImage.url} preloadImgSrc={featuredProj[2].mainImage.url} rotate={44} w={"74%"} h={"450px"} backgroundColor={featuredProj[2].colour} posTranslateX={"15%"} posTranslateY={"0%"} rotatePos1={-1} rotatePos2={2} link={featuredProj[2].projectSlug} linkText={featuredProj[2].projectTitle} tags={featuredProj[2].projectTagCollection.items} animateFlag={roll2} />
                    }

                    {/* Render featured project three in left bottom position */}
                    {featuredProj[3] &&
                        <Featured imgSrc={featuredProj[3].mainImage.url} preloadImgSrc={featuredProj[3].mainImage.url} rotate={44}  w={"62%"} h={"500px"} backgroundColor={featuredProj[3].colour} posTranslateX={"4%"} posTranslateY={"0%"} rotatePos1={-1} rotatePos2={2} link={featuredProj[3].projectSlug} linkText={featuredProj[3].projectTitle} tags={featuredProj[3].projectTagCollection.items} animateFlag={roll2} />
                    }




                </section>
                :
                <p className="loading">Loading</p>
            }
            <div id="aboutSection">

                <div className="largeButton black">
                    <Link to='/about'>
                        <p> SEE MORE WORK ↗</p>
                    </Link>
                </div>


                <div className="largeButton white">
                    <Link to='/about'>
                        <p >ABOUT ↗</p>
                    </Link>
                </div>

            </div>
            <Footer />









        </div>

    );


}


