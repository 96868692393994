import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ProgressiveImage from "react-progressive-image";
import { useMediaQuery } from 'react-responsive';
import { Tag } from './Tag';
//import ReactDOM from 'react-dom';



//Featured Image

export const Featured = ({ imgSrc, preloadImgSrc, rotatePos1, rotatePos2, w, h, backgroundColor, posTranslateX, posTranslateY, link, linkText, tags, animateFlag }) => {

    const [rollOver, setRollOver] = useState(false);

    const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

    useEffect(() => {

        if (isMobile == true) {

            setRollOver(true);

        };

        // console.log(isMobile == true );
        // console.log(rollOver);

    }, [isMobile])

    // console.log(tags);

    const transtion = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };
    const transtion2 = { duration: 0.7, ease: [0.6, 0.01, -0.05, 0.96] };

    const styleDesktop = {

    }

    return (
        <motion.div
            style={{
                height: `${h}`
            }}

            animate={{
                opacity: animateFlag ? 1 : 0,
                y: animateFlag ? -20 : 10,
                transition: {  ...transtion }
            }}
        // transition= 'transtion'

        >

            <ProgressiveImage
                src={imgSrc}
                placeholder={preloadImgSrc}
            >
                {(src, loading) => (
                    <motion.div style={isMobile ? {
                        width: "100%",
                        height: `${h}`,
                        background: `${backgroundColor}`,
                        transform: "translate(0)",
                        backgroundImage: `url(${src})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"


                    } : {
                        width: `${w}`,
                        height: `${h}`,
                        background: `${backgroundColor}`,

                        backgroundImage: `url(${src})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",

                    }}

                        onHoverStart={() => {

                            (isMobile ? setRollOver(false) : setRollOver(true));
                        }}
                        onHoverEnd={() => {
                            (isMobile ? setRollOver(false) : setRollOver(false));
                        }}

                        initial={{
                            x: isMobile ? "1%" : posTranslateX,
                            y: posTranslateY
                        }}

                        animate={{
                            rotate: rollOver ? rotatePos1 : rotatePos2,
                            transition: { ...transtion2 }


                        }}
                        className="featuredWork">

                        <Link className="projectLink" to={'/work/' + link}>
                            <motion.div style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                background: `${backgroundColor}` + "66",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}

                                animate={{
                                    opacity: rollOver ? 1 : 0,

                                    transition: { ...transtion2 }
                                }}

                               


                            >
                                <h2> {linkText}</h2>

                                <div>

                                    {tags &&
                                        tags.map((tag, idx) => {
                                            return <Tag key={idx} tagName={tag.tagName} smallFlag={true} />

                                        })

                                    }
                                </div>
                            </motion.div>

                        </Link>

                    </motion.div>
                )}
            </ProgressiveImage>




        </motion.div >

    );


}


