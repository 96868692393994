import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';




export const ParagraphComponent = ({ data }) => {


    return (

        <div class="paragraphComponent"
        style={{
            backgroundColor: data.color
        }} >

            {documentToReactComponents(data.paragraph.json)}

            { data.image1 && <img src={data.image1.url} alt={data.image1.title} width="500" height="600"/> }


        </div>

    );


}


