import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, useLocation, Link } from 'react-router-dom';

import { Home } from './screen/Home.jsx';
import { About } from './screen/About.jsx';
import { Work } from './screen/Work.jsx';
import { Project } from './screen/Project.jsx';
import { Test } from './screen/Test.jsx';
import { Nav } from './components/Nav';
import { Footer } from './components/Footer';


export function App() {

  return (
    //Router navigates the "page" in the broswer search bar to the correct screen component as listed below

    //Switch ensures that each page component is displayed on its own. And the URL has to be an 'exact match'
    <Router>
      
      <Switch>
        <Route path="/" exact >

          <Nav home={true} />
          <Home />
        </Route>

        <Route path="/about" exact>
          <Nav />
          <About />
          <Footer/>

        </Route>
        <Route path="/work" exact component={Work}>
          <Nav />
          <Work />
          <Footer/>

        </Route>
        <Route path="/work/:projSlug" component={Project}>

          {/* <Nav pageDisplay={"projectPage"} /> */}
        </Route>

        <Route path="/test" component={Test}></Route>
      </Switch>
      {/* Navigation */}



    </Router>

  );
}

