import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';




export const LargeMediaVideo = ({ data }) => {


    return (

        <div class="LargeMediaVideo" 
        style={{
            backgroundColor: data.color
        }}
        >
            <p>LargeMediaVideo</p>

            {/* {documentToReactComponents(data.paragraph.json)}

            <img src={data.image1.url} alt={data.image1.title} width="500" height="600"/> */}

            {/* <iframe src="https://www.youtube-nocookie.com/embed/IDiFMliQUOo?rel=0&amp;controls=0&amp;autoplay=1&amp;mute=1&amp;start=0" frameBorder="0" style={{position:absolute, left: 0 ,top:0 , width: "100%",height:"100%", pointerEvents:auto}} allow= {{autoplay; encrypted-media allowFullscreen=""></iframe> */}


        </div>

    );


}



