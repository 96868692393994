import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';




export const TwoColumnTextMedia = ({ data }) => {


    return (

        <div className="TwoColumnTextMedia"
        style={{
            backgroundColor: data.color
        }} >

            <div id="col1">
                {data.col1Text && documentToReactComponents(data.col1Text.json)}
                {data.col1Media && <img src={data.col1Media.url} alt={data.col1Media.title} />}
            </div>

            <div id="col2">
                {data.col2Text && documentToReactComponents(data.col2Text.json)}
                {data.col2Media && <img src={data.col2Media.url} alt={data.col2Media.title}  />}
            </div>


        </div>

    );


}


