import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';




export const LeftAlignedTextMedia = ({ data }) => {


    return (

        <div className="LeftAlignedTextMedia" 
        style={{
            backgroundColor: data.color
        }}>

            <div id="col1">
                {documentToReactComponents(data.sectionText.json)}
                <img src={data.media.url} alt={data.media.title} />
            </div>

           


        </div>

    );


}



