import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';




export const FullTextPage = ({ data }) => {


    return (

        <div class="FullTextPage"
        style={{
            backgroundColor: data.color
        }} >

            {documentToReactComponents(data.sectionText.json)}
            { data.ctaLink ? documentToReactComponents(data.ctaLink.json): null}


        </div>

    );


}


