import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { Featured } from '../components/Featured';

import jeux from '../assets/friends-stickers-jeux.png';
import suum from '../assets/suum-mockup.png';

export const Test = () => {




    return (

        <div >

            <Featured imgSrc={jeux} preloadImgSrc={jeux} rotate={ 44 } w={ "70%"} h={"60%"} backgroundColor={"#EDEDED"} posTranslate={"-30%"} rotatePos1={4} rotatePos2={2}/>


        </div>

    );


}


