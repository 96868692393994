import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { WorkList } from '../components/WorkList';

const query = `
{
    projectCollection{
      total
      items{
        projectSlug
        projectTitle
        projectOverview
      }
    }
  }
`;



export const Work = () => {

    const [page, setPage] = useState(null);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

        fetchWork();

    }, []);

    const [projects, setProjects] = useState([]);

    const fetchWork = async () => {
        const data = await axios(
            {
                method: 'post',
                url: "https://graphql.contentful.com/content/v1/spaces/3mlo7pqpvlcv/",

                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer uff5octNHq6dSeF3yn9g294jTO0-oe37un12WtJpK-s"
                },
                // auth: "Bearer uff5octNHq6dSeF3yn9g294jTO0-oe37un12WtJpK-s",
                // send the GraphQL query
                data: JSON.stringify({ query }),

            })

            .then((response) => {
                const projectReturn = response.data.data.projectCollection.items;
                setProjects(projectReturn);
                console.log(projectReturn);

                // console.log(projectReturn.);

            });

        // console.log(projects.data.projectCollection.items[1]);

        //const items = await data.json();
        //console.log(items);

    }

    if (!projects) {
        return "Loading...";
    }


    return (

        //Create component that gets imported here that gets all projects and lists them as <h3> tag. Also pull all the display images.
        <div id="workSection" >


            {projects.map(item => (

                <WorkList key={item.projectSlug} projectSlug={item.projectSlug} projectTitle={item.projectTitle}/>
            ))}

            {/* {projects.map(item => (
                <h3 class="projectListTitle" key={item.projectSlug}>
                    <Link to={`/work/${(item.projectSlug)}`}>
                    {item.projectTitle} </Link>
                </h3>
            ))} */}



        </div>

    );


}


