import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';




export const ParagraphComponent2 = ({ data }) => {


    return (

        <div class="paragraphComponent2" 
        style={{
            backgroundColor: data.color
        }}>

            {documentToReactComponents(data.paragraph.json)}
        <div>
            { data.image1 && <img src={data.image1.url} alt={data.image1.title} /> }
            { data.image2 &&  <img src={data.image2.url} alt={data.image2.title} /> }
            </div>


        </div>

    );


}


