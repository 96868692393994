import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { FullTextPage } from '../pageSections/FullTextPage'
import { ParagraphComponent } from '../pageSections/ParagraphComponent.jsx'
import { ParagraphComponent2 } from '../pageSections/ParagraphComponent2.jsx'
import { TwoColumnTextMedia } from '../pageSections/TwoColumnTextMedia';
import { ProjectProcess } from '../pageSections/ProjectProcess';
import { LargeMediaVideo } from '../pageSections/LargeMediaVideo';
import { LeftAlignedTextMedia } from '../pageSections/LeftAlignedTextMedia';



export const Layout2 = ({ data }) => {

    console.log(data.layoutComponentsCollection.items);
    console.log(data);

    return (

        <div id="Layout2">

            {data.layoutComponentsCollection.items.map(item => {

                if (item.__typename == "ParagraphComponent") {
                    return <ParagraphComponent key={item.id} data={item} />
                }

                if (item.__typename == "ParagraphComponent2") {
                    return <ParagraphComponent2 key={item.id} data={item} />
                }

                if (item.__typename == "FullTextPage") {
                    return <FullTextPage key={item.id} data={item} />
                }

                if (item.__typename == "LargeMediaVideo") {
                    return <LargeMediaVideo />
                }

                if (item.__typename == "LeftAlignedTextMedia") {
                    return <LeftAlignedTextMedia key={item.id} data={item}/>
                }

                if (item.__typename == "TwoColumnTextMedia") {
                    return <TwoColumnTextMedia key={item.id} data={item}
                    />
                }

                if (item.__typename == "ProjectProcess") {
                    return <ProjectProcess key={item.id} data={item}/>
                }

                
                    

            }



            )}

        </div>

    );


}


