import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { Nav } from '../components/Nav';
import { Footer } from '../components/Footer';
import ProgressiveImage from "react-progressive-image";
import { Layout2 } from '../components/Layout2';
import { Tag } from '../components/Tag';
import { useMediaQuery } from 'react-responsive';




export const Project = ({ match }) => {

	//Test to see if im able to retrive project slug
	// console.log(match.params.projSlug);

	const [project, setProject] = useState({});
	const [projectData, setProjectData] = useState(false);
	const isMobile = useMediaQuery({ query: '(max-width: 425px)' })


	//Query to get project information for the specific project clicked on
	const query = `
	{
		projectCollection(limit: 1, where:{
		  
		  
		   projectSlug: "${match.params.projSlug}"
		  
		}){items{
			featured
			projectLayout
			projectSlug 
			projectTitle
			projectOverview
			projectTagCollection{items{
			  tag
			  tagName
			}}
			mainImage{
			  title
			  description
			  size
			  width
			  height
			  url
			}
			tools
			team
			role
			colour

			layoutComponentsCollection(limit: 20) {
				items {
				  __typename
				  ... on ParagraphComponent {
					color
					paragraph {
					  json
					}
					image1 {
					  title
					  description
					  size
					  width
					  height
					  url
					}
				  }
				  ... on ParagraphComponent2 {
					color
					paragraph {
					  json
					}
					image1 {
					  title
					  description
					  size
					  width
					  height
					  url
					}
					image2 {
					  title
					  description
					  size
					  width
					  height
					  url
					}
				  }
				  ... on FullTextPage {
					color
					sectionText {
					  json
					}
					ctaLink {
					  json
					}
				  }
				  ... on TwoColumnTextMedia {
					color
					col1Text {
					  json
					}
					col2Text {
					  json
					}
					col1Media {
					  title
					  description
					  size
					  width
					  height
					  url
					}
					col1ExternalMediaLink
					col2Media {
					  title
					  description
					  size
					  width
					  height
					  url
					}
					col2ExternalMediaLink
				  }
				  ... on LargeMediaVideo {
					color
					media {
					  title
					  description
					  size
					  width
					  height
					  url
					}
					externalMediaLink
					mediaDescription
				  }
				  ... on LeftAlignedTextMedia {
					color
					sectionText {
					  json
					}
					media {
					  title
					  description
					  size
					  width
					  height
					  url
					}
				  }

				  ... on ProjectProcess{
					color
					processList{
					  json
					}
				  }
				}
			  }
			
	  
		  
		  
		}}
	  }	  
		`;


	useEffect(() => {

		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		
		const fetchProjectInfo = async () => {

			const data = await axios(
				{
					method: 'post',
					url: "https://graphql.contentful.com/content/v1/spaces/3mlo7pqpvlcv/",

					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer uff5octNHq6dSeF3yn9g294jTO0-oe37un12WtJpK-s"
					},
					// auth: "Bearer uff5octNHq6dSeF3yn9g294jTO0-oe37un12WtJpK-s",
					// send the GraphQL query
					data: JSON.stringify({ query }),

				})

				.then((response) => {
					const projectReturn = response.data.data.projectCollection.items[0];
					setProject(projectReturn);
					setProjectData(true);
					//console.log(projectReturn);

					console.log(projectReturn);

				}).catch(err => {
					setProjectData(false);
				});


			// const items = await fetchItem.json();
			// console.log(items);

			// setProject(items[id]);

			// (project != null  ? console.log("happy") : console.log("sad"));
		}

		fetchProjectInfo();

	}, []);







	if (projectData) return (

		//Create component that gets imported here that gets all projects and lists them as <h3> tag. Also pull all the display images.
		<div>
			<Nav />

			<div id="imagePlacementProject"
				style={{
					backgroundColor: project.colour
				}}>
				<ProgressiveImage

					src={project.mainImage.url}
					placeholder={project.mainImage.url}>
					{(src, loading) => (
						<div
							id="projectMainImage"

							style={ isMobile ? {
								opacity: "50%",
								backgroundImage: `url(${src})`,
								backgroundSize: "cover",
								backgroundPosition: "center"
							} : {

								opacity: loading ? 0.5 : 1,
								backgroundImage: `url(${src})`,
								backgroundSize: "cover",
								backgroundPosition: "center"

							}}
							src={src}
							alt='alt text'

							


						></div>
					)}

				</ProgressiveImage>
			</div>


			<section id="projectPageSection"

			>


				<div id="projectHeroSection">
					<h2>{project.projectTitle}</h2>

					<div id="projectTitleSection">



						{
							project.projectTagCollection.items.map((tag, idx) => {
								return <Tag key={idx} tagName={tag.tagName} />

							})

						}

					</div>


					<div id="projectInfoSection">

						<p id="projectOverview">{project.projectOverview}</p>

						<div className="projectInfoBox">
							<h4>Role</h4>
							<p>{project.role}</p>
						</div>

						<div className="projectInfoBox">
							<h4>Tools</h4>
							<p>{project.tools}</p>
						</div>

						<div className="projectInfoBox">
							<h4>Team</h4>
							<p>{project.team}</p>
						</div>

					</div>





				</div>


			</section>





			{project.projectLayout == 2 ?

				<Layout2 data={project} />

				: null
			}





			<Footer />
		</div>

	);

	if (!projectData) return (<p className="loading" >loading</p>);



}


