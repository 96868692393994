import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
//import ReactDOM from 'react-dom';
import ProgressiveImage from "react-progressive-image";
import { Footer } from '../components/Footer';
import { Contact } from '../components/Contact';
import { useMediaQuery } from 'react-responsive';


import floatingSmile from '../assets/seyitan2.png';
import floatingSmilePlaceholder from '../assets/seyitan2.png';

import resume from '../assets/Seyitan Oke_Resume_OCT2021-Design.pdf' 




export const About = () => {

	const [isShown, setIsShown] = useState(false);
	const [cursorXY, setCursorXY] = useState({ x: 0, y: 0 });
	const isMobile = useMediaQuery({ query: '(max-width: 425px)' })

	useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

		if (isMobile) {

		};

		const moveCursor = (e) => {
			const x = e.clientX - 200;
			const y = e.clientY - 200;
			const xT1 = e.clientX - 250;
			const yT1 = e.clientY - 280;

			const xT2 = e.clientX - 525;
			const yT2 = e.clientY - 250;

			const xT3 = e.clientX - 525;
			const yT3 = e.clientY - 200;

			//console.log(e);
			//console.log(window.innerWidth,window.innerHeight);
			// console.log(map_range( xT, 0, 400, 0, window.innerHeight), "Map Range"); 
			// console.log("xT:", xT1, " yT:", yT1);
			//console.log("x:",x, " y:", y);
			setCursorXY({ x, y, xT1, yT1, xT2, yT2, xT3, yT3 })
		}

		window.addEventListener('mousemove', moveCursor)
		return () => {
			window.removeEventListener('mousemove', moveCursor)
		}

		// window.scrollTo(0, 0);
	}, [])

	// const transtionPic = { duration: 0.2, ease: [0.43, 0.13, 0.23, 0.96] };

	return (

		//add imported classes here
		//always have div to place component notes
		<div>
			<section id="aboutPageSection">


			<ProgressiveImage

				src={floatingSmile}
				placeholder={floatingSmilePlaceholder}>
				{(src, loading) => (
					<img
					// key="floatingImage"
					style={ isMobile ? {
								opacity: loading ? 0.5 : 1,
								position: "absolute",
								right: "35%",
								top: "22%",
								height: "300px",
								rotate: "-6deg"
							} : {

								opacity: loading ? 0.5 : 1,
								position: "absolute",
								left: "62%",
								top: "10%",
								height: "600px",
								rotate: "-4deg"

					}}


					

					src={src}
					alt='floating Photo of Seyitan Oke'
					
					></img>
				)}

			</ProgressiveImage>	
			
				<div>
				
				<motion.h1
					onMouseEnter={() => setIsShown(true)}
					onMouseLeave={() => setIsShown(false)}

				>This is <span className="roundedBoxText black aboutMod" style={{ backgroundColor: "#f87d7d", borderColor: "#f87d7d" }}>Seyitan Oke</span>
				
				</motion.h1>

				<p>A curious designer on a
				 mission close the gap between design and development.</p>
				<p>Based in Toronto.</p>

				</div>
			


				

				



				<div id="moreAbout">
					<div >
						<div id="education">
							<h3>Education</h3>
							<p>University of Waterloo
							School of Interaction Design and Business Bachelor of Global Business & Digital Arts
							
							<br/>
							<br/>

							Sheridan College
							Interactive Multimedia Management- Post Graduate Certiﬁcate
							
						</p>
						</div>

						<div id="journey">

							<h3>Design &#38; Dev Journey</h3>
							<p>Design, engineering, marketing, leadership. <br/>
							Curiosity across disciplines is his special power that enables better problem framing and understanding. He prides himself in being a user centric product thinker that lobbies and inspires people across design, technical, and business teams.
							<br/>
							<br/>

							For the past few years, he has led interdisciplinary teams to build software for companies transforming their digital products. Before that, he graduated from School of Interaction Design and Business at University of Waterloo in 2018.
							</p>
							<h3>What I’m doing Now!</h3>
							<p>He is increasingly interested in AR/VR experiences, community building for students, and developing for the web.</p>

						</div>
					</div>

					<div id="getInTouch">
						<h3 
						style={{
							textShadow: "0px 0px 10px #00000040"
						}} 
						
						>Get In Touch</h3>
						<div>
							<a class="box" href={resume}>Resume ↗ </a>
							<div>
								<a className="socials" href="https://twitter.com/thisisoke"> <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" class="svg-inline--fa fa-twitter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg></a>
								<a className="socials" href="mailto:seyitanoke@gmail.com?subject=Hi%20Seyitan" ><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" class="svg-inline--fa fa-envelope fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg></a>
								<a className="socials" href="https://www.linkedin.com/in/seyitan-oke/" target="_blank" ><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" class="svg-inline--fa fa-linkedin-in fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg></a>
							</div>
						</div>
					</div>


				</div>

			</section>




		</div>

	);


}


