import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
//tags component
//it is a link  made to look lie a buton
//accepts tagName and onclick even

export const CircleRotate = ({ text }) => {

    return (
        <div id="circle">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100">
                <defs>
                    <path
                        // animate={{ rotate: 360}}
                        // transition={{ repeat: Infinity, duration: 15, ease: "linear" }}
                        id="textcircle"
                        class="cls-1" d="M50.25,100.25a50,50,0,1,1,50-50A50.24,50.24,0,0,1,50.25,100.25Z"  >

                    </path>

                </defs>
                <g>
                    <text dy="14" textLength="10"  >

                        <textPath 
                            href="#textcircle" 
                            lengthAdjust="spacing"
                            method="stetch"
                            // style= "fill:black"
                             >{text} </textPath>
                    </text>
                    <animateTransform
                        attributeName="transform"
                        begin="0s"
                        dur="15s"
                        type="rotate"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite"
                        transform-origin="0 0"
                    />


                </g>

            </svg>
        </div>

    )



}



