import React, { useEffect, useState } from 'react';

//tags component
//it is a link  made to look lie a buton
//accepts tagName and onclick even

export const Tag = ({ tagCode, tagName, handleOnClick, smallFlag }) => {
    
    return (
        <div >
           <p className={smallFlag ?  "projectTagBox small" :  "projectTagBox" } onClick={handleOnClick}> {tagName} </p>
        </div>

    )



}