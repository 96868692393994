import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
//import ReactDOM from 'react-dom';




export const Nav = ({ home }) => {



  return (

    <div >
      {home? 
      <div class="navBar" >
      {/* <Link to='/about'>about</Link>
      <Link to='/work'>work</Link> */}
      </div>
      
      : 
      
      <div class="navBarFull" >
      <Link class="logoNav"to='/'>thisisoke</Link>
      <div>
      <Link to='/about'>ABOUT</Link>
      <Link to='/work'>WORK</Link>
      </div>
      
      {/* <Link to='/resume'>resume</Link> */}
      </div>

       }
      
    </div>

  );


}


